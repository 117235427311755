<template>
  <b-card
    no-body
    class="card-statistics"
  >
    <b-card-header>
      <b-card-title>总数据</b-card-title>
      <b-card-text class="font-small-2 mr-25 mb-0">
        数据更新于：{{ DateUpdate }}
      </b-card-text>
    </b-card-header>
    <b-card-body class="statistics-body">
      <b-row>
        <b-col
          xl="3"
          sm="6"
          class="mb-2 mb-xl-0"
        >
          <b-media no-body>
            <b-media-aside class="mr-2">
              <b-avatar
                size="48"
                variant="light-warning"
              >
                <feather-icon
                  size="24"
                  icon="UserIcon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ DriversSum }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                <h-link
                  text="参赛车手"
                  to="drivers"
                />
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>

        <b-col
          xl="3"
          sm="6"
          class="mb-2 mb-xl-0"
        >
          <b-media no-body>
            <b-media-aside class="mr-2">
              <b-avatar
                size="48"
                variant="light-info"
              >
                <feather-icon
                  size="24"
                  icon="FlagIcon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ RacesSum }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                <h-link
                  text="比赛"
                  to="races"
                />
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>

        <b-col
          xl="3"
          sm="6"
          class="mb-2 mb-xl-0"
        >
          <b-media no-body>
            <b-media-aside class="mr-2">
              <b-avatar
                size="48"
                variant="light-danger"
              >
                <feather-icon
                  size="24"
                  icon="CircleIcon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ TracksSum }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                <h-link
                  text="赛道"
                  to="tracks"
                />
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>

        <b-col
          xl="3"
          sm="6"
        >
          <b-media no-body>
            <b-media-aside class="mr-2">
              <b-avatar
                size="48"
                variant="light-success"
              >
                <feather-icon
                  size="24"
                  icon="ListIcon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ SeriesSum }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                <h-link
                  text="赛事系列"
                  to="series"
                />
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import Vue from 'vue'
import {
  BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody,
} from 'bootstrap-vue'
import axios from '@axios'
import HLink from '@/views/common/h-link.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    HLink,
  },
  data() {
    return {
      DriversSum: '',
      RacesSum: '',
      TracksSum: '',
      SeriesSum: '',
      DateUpdate: '',
    }
  },
  created() {
    axios.get(Vue.prototype.$Api_Dashboard_Count)
      .then(response => {
        this.DriversSum = response.data[0].drivers
        this.RacesSum = response.data[0].races
        this.TracksSum = response.data[0].series
        this.SeriesSum = response.data[0].tracks
      })
    axios.get(Vue.prototype.$Api_Dashboard_DateUpdate)
      .then(response => {
        this.DateUpdate = response.data[0].DateUpdate
        console.log('当你看到这里，你下一场比赛会DQ或者DNF。')
      })
  },
}
</script>
