<template>
  <!-- Todo:
  3. 规则等说明性页面
  4. 外链菜单到主站
  5. 首页左上角metal内容
  6. 移动端的菜单，以及移动端的布局检查
  7. 面包屑的优化
  8. 联赛详情的赛事等级
  9. 联赛和赛事卡片的缩略图
  10. 浏览器标题
  11. 更新历史
  -->
  <section id="dashboard">
    <b-row class="match-height">
      <b-col
        xl="4"
        md="6"
      >
        <DashboardMedal />
      </b-col>
      <b-col
        xl="8"
        md="6"
      >
        <DashboardCount />
      </b-col>
    </b-row>

    <b-row class="match-height">
      <!-- 最近赛事表格 -->
      <b-col lg="8">
        <DashboardLatestRaces />
      </b-col>
      <!--/ 最近赛事表格 -->

      <!-- HiGarage介绍表格 -->
      <b-col
        lg="4"
        md="6"
      >
        <b-card>
          <b-card-title>
            关于数据中心 <small class="text-muted">About</small>
          </b-card-title>
          <b-card-text>
            数据中心是嗨跑赛车面向参赛车手的一个数据服务平台。这个小小的数据平台也在吸取广大车手的提议，逐渐变得更强大、更好玩、更专业，也更有趣。
          </b-card-text>
          <b-card-title>
            最近更新 <small class="text-muted">V2.0.0</small>
          </b-card-title>
          <b-card-text>
            V2版本。使用船新的前端架构，优化了性能，统一了数据展示，解决了查询车手数据等问题
          </b-card-text>
        </b-card>

        <DashboardRankRating />
      </b-col>
      <!--/ HiGarage介绍表格 -->
    </b-row>
  </section>
</template>

<script>
import {
  BRow, BCol, BCard, BCardText, BCardTitle,
} from 'bootstrap-vue'
import DashboardMedal from './DashboardMedal.vue'
import DashboardCount from './DashboardCount.vue'
import DashboardLatestRaces from './DashboardLatestRaces.vue'
import DashboardRankRating from './DashboardRankRating.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    BCardTitle,
    DashboardMedal,
    DashboardCount,
    DashboardLatestRaces,
    DashboardRankRating,
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
</style>
