<template>
  <b-card-code
    title="近期赛事"
    no-body
  >
    <b-table
      :fields="fields"
      :items="items"
      responsive
      class="mb-0"
    >

      <template #cell(index)="data">
        {{ data.index + 1 }}
      </template>

      <template #cell(name)="data">
        <h-link
          :id="data.item.Rid"
          :text="data.item.RaceName.substr(data.item.RaceName.indexOf(' '))"
          to="race"
          type="race"
          target="_blank"
        />
      </template>

      <template #cell(track)="data">
        <h-link
          :id="data.item.tid"
          :text="data.item.TrackNameAbbr"
          to="track"
          type="track"
          target="_blank"
        />
      </template>

      <template #cell(date)="data">
        <span class="text-nowrap">{{ data.item.RaceDate }}</span>
      </template>

      <template #cell(class)="data">
        <span class="text-nowrap">{{ data.item.Class }}</span>
      </template>

      <template #cell(platform)="data">
        <span class="text-nowrap">{{ data.item.Platform }}</span>
      </template>

    </b-table>

  </b-card-code>
</template>

<script>
import Vue from 'vue'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { BTable } from 'bootstrap-vue'
import axios from '@axios'
import HLink from '@/views/common/h-link.vue'

export default {
  components: {
    BCardCode,
    BTable,
    HLink,
  },
  data() {
    return {
      fields: [
        { key: 'index', label: '#' },
        { key: 'name', label: '赛事名称' },
        { key: 'date', label: '日期' },
        { key: 'class', label: '组别' },
        { key: 'track', label: '赛道' },
        { key: 'platform', label: '平台' },
      ],
      items: [],
    }
  },
  created() {
    axios.get(`${Vue.prototype.$Api_Dashboard_LatestRaces}15`)
      .then(response => {
        this.items = response.data
      })
  },
}
</script>
