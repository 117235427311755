<template>
  <b-card>
    <b-card-title>
      HiRating榜
    </b-card-title>
    <b-table
      :fields="fields"
      :items="items"
      responsive
      class="mb-0"
      thead-class="d-none"
    >
      <template #cell(index)="data">
        {{ data.index + 1 }}
      </template>

      <template #cell(nickname)="data">
        <h-link
          :id="data.item.Did"
          :text="data.item.nickname"
          to="driver"
          type="driver"
        />
      </template>

      <template #cell(ladder)="data">
        <span class="text-nowrap">{{ data.item.ladder }}</span>
      </template>

      <template #cell()="data">
        {{ data.item }}
      </template>
    </b-table>
  </b-card>
</template>

<script>
import Vue from 'vue'
import {
  BCard, BCardTitle, BTable,
} from 'bootstrap-vue'
import axios from '@axios'
import HLink from '@/views/common/h-link.vue'

export default {
  components: {
    BCard,
    BCardTitle,
    BTable,
    HLink,
  },
  data() {
    return {
      fields: [
        { key: 'index', label: '#' },
        { key: 'nickname', label: '选手' },
        { key: 'ladder', label: 'HiRating' },
      ],
      items: [],
    }
  },
  created() {
    axios.get(`${Vue.prototype.$Api_Rank_Rating}10`)
      .then(response => {
        this.items = response.data
      })
  },
}
</script>
