<template>
  <b-card
    class="card-congratulation-medal"
  >
    <h4>恭喜 🎉 whyyxx !</h4>
    <b-card-text>
      登顶Rating榜首
    </b-card-text>
    <h3 class="mb-75 mt-2 pt-50">
      <b-link>HiRating 2050分</b-link>
    </h3>
    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      href="/driver/781"
      target="_blank"
    >
      查看TA的档案
    </b-button>
    <b-img
      :src="require('@/assets/images/svg/badge.svg')"
      class="congratulation-medal"
      alt="Medal Pic"
    />
  </b-card>
</template>

<script>
import {
  BCard, BCardText, BLink, BButton, BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BCardText,
    BLink,
    BImg,
    BButton,
  },
  directives: {
    Ripple,
  },
}
</script>
